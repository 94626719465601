import { ajax } from '@/utils/request';

// 图片解析
export async function AiAnalyzeImage(data) {
  const result = await ajax({
    method: 'post',
    url: `/ai/analyze/image`,
    headers:{
      "Content-Type": "multipart/form-data"
    },
    ...data
  });
  return result;
}
// 上传本地文件
export async function quotaDataUpload(data, signal) {
  const result = await ajax({
    method: 'post',
    url: `/quotaData/upload`,
    headers:{
      "Content-Type": "multipart/form-data"
    },
    signal: signal,
    data
  });
  return result;
}
// 上传本地文件
export async function quotaDataUploadLoginSkip(data, signal) {
  const result = await ajax({
    method: 'post',
    url: `/quotaData/uploadLoginSkip`,
    headers:{
      "Content-Type": "multipart/form-data"
    },
    signal: signal,
    data
  });
  return result;
}
// 上传已有文件
export async function quotaDataUploadExistFile(data) {
  const result = await ajax({
    method: 'post',
    url: `/quotaData/uploadExistFile`,
    data
  });
  return result;
}
// 上传已有文件, 直接返回所有信息
export async function quotaDataUploadExistFileGetContext(data) {
  const result = await ajax({
    method: 'post',
    url: `/quotaData/uploadExistFile`,
    data
  },{
    getContext: true,
  });
  return result;
}
// 批量上传已有文件, 直接返回所有信息
export async function quotaDataBatchUploadExistFileGetContext(data) {
  const result = await ajax({
    method: 'post',
    url: `/quotaData/batchUploadExistFile`,
    data
  },{
    getContext: true,
  });
  return result;
}
// 上传已有文件
export async function quotaDataUploadExistFileLoginSkip(data) {
  const result = await ajax({
    method: 'post',
    url: `/quotaData/uploadExistFileLoginSkip`,
    data
  });
  return result;
}
// 查询关键指标文件
export async function quotaDataQueryFile(dataId) {
  const result = await ajax({
    method: 'get',
    url: `/quotaData/queryFile?dataId=${dataId}`,
  });
  return result;
}
// 财报解析
export async function quotaDataAnalyzeReport(data, signal) {
  const result = await ajax({
    method: 'post',
    url: `/quotaData/analyzeReport`,
    signal: signal,
    data,
  // },{
  //   getContext: true,
  // });
  });
  return result;
}
// 财报解析
export async function quotaDataAnalyzeReportLoginSkip(data, signal) {
  const result = await ajax({
    method: 'post',
    url: `/quotaData/analyzeReportLoginSkip`,
    signal: signal,
    data,
  },{
    getContext: true,
  });
  return result;
}
// 财报解析
export async function quotaDataCompare(data) {
  const result = await ajax({
    method: 'post',
    url: `/quotaData/compare`,
    data,
  });
  return result;
}
// 根据fileToken获取文件url
export async function fileQueryFileByToken(fileToken, fileType, signal) {
  const result = await ajax({
    method: 'get',
    url: `/file/queryFileByToken?fileToken=${fileToken}&fileType=${fileType}`,
    signal: signal,
  });
  return result;
}
// 根据fileToken获取文件url
export async function fileQueryFileByTokenLoginSkip(fileToken, fileType, tenantId, userId, signal) {
  const result = await ajax({
    method: 'get',
    url: `/file/queryFileByTokenLoginSkip?fileToken=${fileToken}&fileType=${fileType}&tenantId=${tenantId}&userId=${userId}`,
    signal: signal,
  });
  return result;
}
// 根据查询类型查询推荐文件
export async function getRecommendFileByType(data) {
  const result = await ajax({
    method: 'post',
    url: `/mixData/getRecommendFileByType`,
    data,
  });
  return result;
}
// 获取财务指标
export async function quotaGetFinanceQuota(assetTypeId) {
  const result = await ajax({
    method: 'post',
    url: `/quota/getFinanceQuota?assetTypeId=${assetTypeId}`,
  });
  return result;
}
// 文件归档
export async function quotaDataFileArchiving(data) {
  const result = await ajax({
    method: 'post',
    url: `/quotaData/fileArchiving`,
    data
  });
  return result;
}
// 多个文件归档
export async function quotaDataBatchFileArchiving(data) {
  const result = await ajax({
    method: 'post',
    url: `/quotaData/batchFileArchiving`,
    data
  });
  return result;
}
// 文件归档
export async function fileChangeShareAuth(data, signal) {
  const result = await ajax({
    method: 'post',
    url: `/file/changeShareAuth`,
    signal: signal,
    data
  },{
    getContext: true,
  });
  return result;
}
// 查询AiCount
export async function tenantGetAiCount(extra) {
  const result = await ajax({
    method: 'get',
    url: `/tenant/getAiCount`,
  });
  return result;
}
// 查询AiCount
export async function tenantGetAiCountLoginSkip(tenantId) {
  const result = await ajax({
    method: 'get',
    url: `/tenant/getAiCountLoginSkip?tenantId=${tenantId}`,
  });
  return result;
}
// 查询AiCount
export async function tenantGetConfig(tenantId, key) {
  const result = await ajax({
    method: 'get',
    url: `/tenant/getConfig?tenantId=${tenantId}&key=${key}`,
  });
  return result;
}
// 查询最近一次编辑的投前或者投后项目
export async function getLastEditProject(extra) {
  const result = await ajax({
    method: 'get',
    url: `/mixData/queryLatestEditData?businessType=${extra?.businessType}&aiFunction=${extra?.aiFunction}`,
  });
  return result;
}
// 获取AI字段的文件
export async function queryAiFileFieldInfo(bCode, id) {
  const result = await ajax({
    method: 'get',
    url: `/${bCode}/queryAiInfo?id=${id}`,
  });
  return result;
}
// ai字段数量检查
export async function aiCheckFieldsNum(businessCode, assetTypeId, aiFunction) {
  const result = await ajax({
    method: 'get',
    url: `/ai/checkFieldsNum?businessCode=${businessCode}&assetTypeId=${assetTypeId}&aiFunction=${aiFunction}`,
  });
  return result;
}
// 待处理
export async function aiParsePendingList(businessCode, assetTypeId) {
  const result = await ajax({
    method: 'get',
    url: `/ai/parse/pending/list?businessCode=${businessCode}&assetTypeId=${assetTypeId}`,
  });
  return result;
}
// 投资协议和尽调解析
export async function mixDataAnalyzeReport(data) {
  const result = await ajax({
    method: 'post',
    url: `/mixData/analyzeReport`,
    data
  });
  return result;
}
// 获取指定报告期的文件
export async function quotaDataQueryAiQuota(data) {
  const result = await ajax({
    method: 'post',
    url: `/quotaData/queryAiQuota`,
    data
  });
  return result;
}
// 查询最新报告期
export async function quotaDataQueryNewest(dataId) {
  const result = await ajax({
    method: 'get',
    url: `/quotaData/queryNewest?dataId=${dataId}`,
  });
  return result;
}

export async function quotaDataAnalyzeReportResult(id) {
  const result = await ajax({
    method: 'get',
    url: `/ai/parse/result/${id}`,
    // signal: signal,
  },{
    getContext: true,
  });
  return result;
}
// 获取框架
export async function getAiFrame(extra) {
  const result = await ajax({
    method: 'post',
    url: `/mixData/getFrame/`,
    ...extra
  });
  return result;
}

export async function getFrameLoginSkip(extra) {
  const result = await ajax({
    method: 'post',
    url: `/mixData/getFrameLoginSkip/`,
    ...extra
  });
  return result;
}

// 轮训差ai协议解析结果
export async function aiXyjxParseResult(id) {
  const result = await ajax({
    method: 'get',
    url: `/ai/parse/result/${id}`,
  });
  return result;
}
// ai解析放弃
export async function aiParseCancel(extra) {
  const result = await ajax({
    method: 'post',
    url: `/ai/parse/cancel`,
    ...extra
  });
  return result;
}
// ai解析放弃 免登
export async function aiParseCancelLoginSkip(extra) {
  const result = await ajax({
    method: 'post',
    url: `/ai/parse/cancelLoginSkip`,
    ...extra
  });
  return result;
}


// 根据AI解析结果，获取解析文件中是否含有子表报告期
export async function fetchAiResult(id) {
  const result = await ajax({
    method: 'post',
    url: `/ai/parse/success/queryObject?id=${id}`,
  });
  return result;
}


// AI解析后入库
export async function saveAiResult(extra) {
  const result = await ajax({
    method: 'post',
    url: `/mixData/aiAnalysisSaveData`,
    ...extra
  });
  return result;
}
// 财报解析上传文件后更新文件到指标文件字段
export async function quotaDataUpdate(extra) {
  const result = await ajax({
    method: 'post',
    url: `/quotaData/update`,
    ...extra
  });
  return result;
}
// 投资协议和尽调解析结果比对
export async function analyzeCompare(extra) {
  const result = await ajax({
    method: 'post',
    url: `/mixData/analyzeCompare`,
    ...extra
  });
  return result;
}
// 子基金遴选，投资协议解析归档文件
export async function archiveWithFileToken(extra) {
  const result = await ajax({
    method: 'post',
    url: `/mixData/archiveWithFileToken`,
    ...extra
  });
  return result;
}
// 根据查询类型查询文件字段
export async function getFileFieldByType(extra) {
  const result = await ajax({
    method: 'post',
    url: `/mixData/getFileFieldByType`,
    ...extra
  });
  return result;
}
//  查询财报指标
export async function queryByFinancialReport(assetTypeId) {
  const result = await ajax({
    method: 'get',
    url: `/quota/queryByFinancialReport?assetTypeId=${assetTypeId}`,
  });
  return result;
}
// 缓存财报解析的一些参数
export async function quotaDataCacheKey(extra) {
  const result = await ajax({
    method: 'post',
    url: `/quotaData/cacheKey`,
    ...extra
  });
  return result;
}